import * as icons from '../../assets/icons'
import { ActionDataTypes, ActionDataWidgets, LinkType } from './types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, Modal, ModalVariants } from '@dtx-company/shared-components/src'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Card } from '@dtx-company/shared-components/src/components/atoms/Card/index'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { DefaultManager, ManagerProps } from './components/Manager/Manager'
import { EditLinkCardEndAdornment } from '../widgets/EditLinkUtils'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FlowpageProps } from './types/FlowpageProps'
import { FormProps, StyledForm } from './components/Layout'
import { FormTitle } from './components/Title'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkStyleGated } from './components/LinkStyle/LinkStyleGated'
import { Polling } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Polling'
import { SUBMIT_VOTE } from '@dtx-company/flow-codegen/src/page/mutations'
import { SettingsAndSaveLink } from './components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { StyledCircularProgress } from '../profile/LinkForm/utils'
import {
  SubmitVoteMutation,
  SubmitVoteMutationVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { defaultWidgetLink } from '../../constants'
import { getLinkCardStyles } from '../flowpage/FlowpageLinkCard/utils/getLinkCardStyles'
import { getValidatedActionData, getValidatedActionDataOrThrow } from './typeUtils'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import { sendSuccessNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { stopPropagationEvents } from '../profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { unparse } from 'papaparse'
import { useClosePoll, useSubmitWidget } from './submitUtils'
import { useFlowpageAnalyticsCollector } from '../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../profile/Customize/hooks'
import { useForm } from 'react-hook-form-deprecated'
import { useIsMobile } from '../../utils/main'
import { useLinkThemeState } from './components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview } from './utils'
import { v4 as uuid } from 'uuid'
import Cookies from 'universal-cookie'
import download from '@dtx-company/true-common/src/utils/download/download'
import styled from 'styled-components'

enum FormFields {
  QUESTION = 'question',
  OPT1 = 'opt1',
  OPT2 = 'opt2',
  DISPLAY_RESULTS = 'displayResults'
}

const DeleteButton = styled(Button)`
  position: absolute;
  top: 13px;
  right: 4px;
  width: 30px;
  background-color: transparent;
  border: none;
`

const getInitialExtraOptions = (options?: {
  opt1: string
  opt2: string
  opt3?: string
  opt4?: string
  opt5?: string
  opt6?: string
}): (string | undefined)[] => {
  if (!options) return []
  const { opt1: _, opt2: __, ...extra } = options
  return extra ? Object.values(extra) : []
}

interface ResultsProps extends BoxProps {
  link: LinkType
  handleClose?: () => void
}

const Results: FC<ResultsProps> = ({ link, handleClose, ...rest }: ResultsProps) => {
  const actionData = getValidatedActionDataOrThrow<'polling'>(link?.actionData, 'question')
  const options = Object.values(actionData?.options || {})
  const results = Object.values(actionData?.results || {})
  const totalVotes = results.reduce((acc, val) => (val && acc !== undefined ? val + acc : acc), 0)
  const closePoll = useClosePoll()
  return (
    <Box
      flexDirection="column"
      width="100%"
      px={[0, 0, '20px']}
      backgroundColor="primary.white"
      {...rest}
    >
      <Box flexDirection="row" alignItems="center" py="16px">
        <Text variant="body/medium" fontWeight="600">
          Poll Results
        </Text>
        <Box ml="20px" height="28px" p="0 8px" backgroundColor="#dadcdd" borderRadius="4px">
          <Text variant="body/small" fontWeight="600" lineHeight="27px" textTransform="uppercase">
            {`${totalVotes} Response${totalVotes !== 1 ? 's' : ''}`}
          </Text>
        </Box>
      </Box>
      <Box pt={[0, 0, '20px']}>
        <Flowpage link={link} overwriteDisplayResult />
      </Box>
      <Box py={[0, 0, '20px']} marginTop="auto" justifyContent="center">
        <Button
          width="160px"
          sizeVariant="large"
          onClick={() => {
            const resultsInPercent = results.map(
              r => Math.round(((Number(r) || 0) * 100) / (totalVotes || 100)) + '%'
            )
            const info = [options, results, resultsInPercent]
            const csvContent = unparse(info)
            const fileName = link.title?.split(' ').join('-')
            download(csvContent, `${fileName}.csv`, 'text/csv;encoding:utf-8')
            handleClose && handleClose()
          }}
        >
          <Text fontWeight="600" color="primary.white">
            Download CSV
          </Text>
        </Button>
        <Button
          ml="40px"
          width="160px"
          sizeVariant="large"
          backgroundColor="#cfdde0"
          border="none"
          display={['none', 'none', 'flex']}
          disabled={actionData?.closed}
          onClick={async (e): Promise<void> => {
            e.preventDefault()
            await closePoll({ curr: link })
          }}
        >
          <Text fontWeight="600" color="primary.black">
            {actionData?.closed ? 'This poll is closed' : 'Close Poll'}
          </Text>
        </Button>
      </Box>
    </Box>
  )
}

export const Form: FC<FormProps> = ({ widgetObj, order, curr, handleClose }: FormProps) => {
  const mobile = useIsMobile()
  const id = curr ? curr.id : uuid()

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const [submitting, setSubmitting] = useState<boolean>(false)
  const actionData = getValidatedActionData<'polling'>(curr?.actionData, 'question')
  const initialExtraOpts = curr ? getInitialExtraOptions(actionData?.options) : []
  const [extraOptions, setExtraOptions] = useState<(string | undefined)[]>(initialExtraOpts)
  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    watch,
    errors
  } = useForm({
    defaultValues: {
      question: actionData?.question || '',
      displayResults: actionData?.displayResults || false,
      opt1: actionData?.options?.opt1 || '',
      opt2: actionData?.options?.opt2 || '',
      closed: curr ? Boolean(actionData?.closed) : false
    }
  })
  const getActionData = useCallback(
    ({
      question,
      options,
      results,
      displayResults
    }: ActionDataTypes['polling']): ActionDataTypes['polling'] => {
      const actionData: ActionDataTypes['polling'] = {
        question,
        options,
        results,
        displayResults
      }

      extraOptions.forEach((opt: string | undefined, i: number) => {
        if (opt) {
          switch (i) {
            case 0:
              actionData.options.opt3 = opt
              actionData.results.opt3 = 0
              break
            case 1:
              actionData.options.opt4 = opt
              actionData.results.opt4 = 0
              break
            case 2:
              actionData.options.opt5 = opt
              actionData.results.opt5 = 0
              break
            case 3:
              actionData.options.opt6 = opt
              actionData.results.opt6 = 0
              break
          }
        }
      })

      return actionData
    },
    [extraOptions]
  )

  const submitWidget = useSubmitWidget()
  const onSubmit = async ({
    question,
    opt1,
    opt2,
    displayResults
  }: {
    question: string
    opt1: string
    opt2: string
    displayResults: boolean
  }): Promise<void> => {
    setSubmitting(true)

    const actionData = getActionData({
      question,
      options: { opt1, opt2 },
      results: { opt1: 0, opt2: 0 },
      displayResults
    })

    await submitWidget({
      curr,
      actionData: { ...actionData, closed: false },
      handleClose,
      linkTheme,
      widgetType: (curr ? curr.type : widgetObj.type) as ActionDataWidgets,
      fields: {
        title: question,
        description: '',
        id,
        order
      }
    })
    setSubmitting(false)
  }

  const watchAll = watch()

  const closePoll = useClosePoll()

  const pollLinkObj: LinkType = useMemo(
    () => ({
      ...defaultWidgetLink,
      type: 'polling',
      actionData: getActionData({
        question: watchAll.question,
        options: { opt1: watchAll.opt1, opt2: watchAll.opt2 },
        displayResults: watchAll.displayResults,
        results: { opt1: 0, opt2: 0 }
      }),
      title: watchAll.question,
      id,
      linkTheme,
      order
    }),
    [
      getActionData,
      id,
      linkTheme,
      order,
      watchAll.displayResults,
      watchAll.opt1,
      watchAll.opt2,
      watchAll.question
    ]
  )
  useEffect(() => {
    register(FormFields.QUESTION, {
      required: 'Please enter a question'
    })
    register(FormFields.OPT1, {
      required: 'Please add an option'
    })
    register(FormFields.OPT2, {
      required: 'Please add an option'
    })
    register(FormFields.DISPLAY_RESULTS)
  }, [register])
  const previewLink = useMemo(
    () => ({
      ...pollLinkObj,
      actionData: getActionData({
        question: watchAll.question,
        options: { opt1: watchAll.opt1, opt2: watchAll.opt2 },
        displayResults: watchAll.displayResults,
        results: { opt1: 0, opt2: 0 }
      })
    }),
    [
      getActionData,
      pollLinkObj,
      watchAll.displayResults,
      watchAll.opt1,
      watchAll.opt2,
      watchAll.question
    ]
  )
  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )
  const edit = Boolean(curr)
  const disabled = !watchAll.opt1 || !watchAll.opt2 || !watchAll.question

  return (
    <>
      <StyledForm
        onSubmit={handleSubmit(onSubmit)}
        title={<FormTitle icon={<Polling />} title={`${edit ? 'Edit this' : 'Add a'} poll`} />}
      >
        {mobile && (
          <Box bg="primary.flowBlue" alignItems="center" height="60px">
            <Text textAlign="center" color="primary.white" pl="24px" width="100%" fontWeight="600">
              {"Note: Polls can't be edited after saved or reopened once closed"}
            </Text>
          </Box>
        )}
        {!mobile && (
          <Box bg="primary.flowBlue" alignItems="center" height="60px" px="8px">
            <Text color="primary.white" textAlign="center" fontWeight="600">
              {"Note: Polls can't be edited after saved or reopened once closed"}
            </Text>
          </Box>
        )}
        <Input
          label="Poll Question*"
          placeholder="Question"
          onChange={e =>
            setValue(FormFields.QUESTION, e.target.value, {
              shouldValidate: true
            })
          }
          value={watchAll.question}
          helperText={errors?.question?.message}
          error={Boolean(errors.question)}
          minWidth="100%"
          type="text"
          disabled={Boolean(curr)}
          {...stopPropagationEvents}
        />
        <Input
          label="Poll Choices"
          placeholder="Option 1"
          {...stopPropagationEvents}
          onChange={e =>
            setValue(FormFields.OPT1, e.target.value, {
              shouldValidate: true
            })
          }
          value={watchAll.opt1}
          helperText={errors?.opt1?.message}
          error={Boolean(errors.opt1)}
          minWidth="100%"
          type="text"
          disabled={Boolean(curr)}
        />
        <Input
          placeholder="Option 2"
          onChange={e =>
            setValue(FormFields.OPT2, e.target.value, {
              shouldValidate: true
            })
          }
          {...stopPropagationEvents}
          value={watchAll.opt2}
          helperText={errors?.opt2?.message}
          error={Boolean(errors.opt2)}
          minWidth="100%"
          type="text"
          disabled={Boolean(curr)}
        />
        {extraOptions.map((opt, i) => (
          <Box position="relative" display="block" key={i}>
            <Input
              placeholder={`Option ${i + 3}`}
              onChange={e => {
                e.preventDefault()
                const options = [...extraOptions]
                options[i] = e.target.value
                setExtraOptions(options)
              }}
              value={opt || ''}
              width="100%"
              maxWidth="100%"
              type="text"
              controlled={true}
              helperText={extraOptions[i] === '' ? 'required' : undefined}
              error={extraOptions[i] === ''}
              disabled={Boolean(curr)}
              {...stopPropagationEvents}
            />
            <DeleteButton
              onClick={e => {
                e.preventDefault()
                const options = [...extraOptions]
                options.splice(i, 1)
                setExtraOptions(options)
              }}
              colorVariant="primaryOnDark"
              disabled={Boolean(curr)}
              {...stopPropagationEvents}
            >
              <Icon src="/static/icons/delete-gray.svg" />
            </DeleteButton>
          </Box>
        ))}
        <Button
          colorVariant={extraOptions.length >= 4 || Boolean(curr) ? 'primary' : 'primaryOnDark'}
          width="164px"
          height="30px"
          border="1px solid #dadcdd"
          disabled={extraOptions.length >= 4 || Boolean(curr)}
          onClick={e => {
            e.preventDefault()
            if (extraOptions.length < 4) {
              const n = [...extraOptions]
              n.push(undefined)
              setExtraOptions(n)
            }
          }}
          {...stopPropagationEvents}
        >
          <Text variant="body/small" fontWeight="600">
            Add Another Option
          </Text>
        </Button>
        <Box mt={['24px', '32px']}>
          <CheckBox
            checked={watchAll.displayResults}
            onChange={e => {
              setValue(FormFields.DISPLAY_RESULTS, e.target.checked)
            }}
            error={Boolean(errors.displayResults)}
            disabled={Boolean(curr)}
            label="Allow people to see results"
          />
        </Box>
        <Button
          sizeVariant="mobileFullWidth"
          colorVariant="tertiary"
          disabled={Boolean(!curr) || Boolean(actionData?.closed)}
          onClick={async (e): Promise<void> => {
            e.preventDefault()
            await closePoll({ curr })
          }}
          {...stopPropagationEvents}
        >
          {actionData?.closed ? 'This poll is already closed' : 'Close Poll'}
        </Button>
        {Boolean(curr) && <Results display={['flex', 'flex', 'none']} link={curr as LinkType} />}
        <Spacer mt="80px" />
        {submitting && <StyledCircularProgress />}

        <Spacer mb="16px" />
        <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkThemeValue} />

        <SettingsAndSaveLink disabled={disabled} curr={curr} handleClose={handleClose} />
      </StyledForm>
    </>
  )
}

export const Flowpage: FC<FlowpageProps> = ({
  link,
  preview,
  overwriteDisplayResult,
  editLinkMode,
  isLockedTemplateLink
}: FlowpageProps) => {
  const cookies = new Cookies()
  const { theme } = useFlowpageTheme()
  const actionData = getValidatedActionData<'polling'>(link?.actionData, 'question')
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(editLinkMode)
  })

  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined)
  const isVoted = selectedOption !== undefined

  const displayResults =
    overwriteDisplayResult || (isVoted && actionData?.displayResults) || actionData?.closed

  const options = Object.values(actionData?.options || {})
  const optionsKeys = Object.keys(actionData?.options || {})

  const [results, setResults] = useState<(number | undefined)[]>(
    Object.values(actionData?.results || {})
  )

  const totalVotes = results?.reduce((acc, val) => (val && acc !== undefined ? val + acc : acc), 0)

  const onVote = async (key: string): Promise<void> => {
    if (!preview) {
      trackLinkClick({ link_id: link.id }, { useHeap: true })
      try {
        const res = await pageGqlFetcher<SubmitVoteMutation, SubmitVoteMutationVariables>(
          SUBMIT_VOTE,
          {
            vote: {
              linkId: link.id,
              vote: key
            }
          }
        )

        if (res?.submitVote) {
          cookies.set('vote_' + link.id, key)
          setSelectedOption(key)
          const actionData = getValidatedActionData<'polling'>(
            res?.submitVote?.actionData,
            'question'
          )
          setResults(Object.values(actionData?.results || {}))
        }
        sendSuccessNotification('Vote submitted')
      } catch (e) {
        console.error(`Polling widget submission failed: ${e.message}`)
      }
    }
  }
  const linkStyle = getLinkCardStyles({
    theme,
    linkTheme: link.linkTheme
  })
  useEffect(() => {
    const hasVotedCookie = cookies.get('vote_' + link.id)

    setSelectedOption(hasVotedCookie)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [link.id])
  return (
    <Card
      {...linkStyle}
      background={linkStyle.background || '#000000'}
      color={linkStyle.color || '#FFFFFF'}
      borderRadius={3}
      data-testid="polling-container"
      id={link.id}
      width="100%"
      mb="25px"
      padding={`8px ${editLinkMode ? '8px' : '16px'} 8px 16px`}
      display="flex"
    >
      <Box width={editLinkMode ? '90%' : '100%'} flexDirection="column">
        <Text
          variant="body/medium"
          color="inherit"
          fontWeight="600"
          fontSize={'inherit'}
          textAlign="center"
          mb="8px"
        >
          {actionData?.question}
        </Text>
        {actionData?.closed && (
          <Text variant="body/small" color="inherit" fontWeight="600" textAlign="center" mb="8px">
            This poll is closed
          </Text>
        )}
        <>
          {options?.map((option: string | undefined, i: number) => {
            const result = results[i] || 0
            const resultInPercent = Math.round((result * 100) / (totalVotes || 100))

            if (option) {
              const isSelectedOption = selectedOption === optionsKeys[i]
              return (
                <Button
                  colorVariant="invisible"
                  key={i}
                  width="100%"
                  height="34px"
                  borderRadius="4px"
                  mb="4px"
                  justifyContent="space-between"
                  alignItems="center"
                  p="5px 5px 5px 22px"
                  position="relative"
                  bg="primary.white"
                  borderColor={
                    displayResults && (!preview || actionData?.closed)
                      ? 'flowpage.primaryColor'
                      : 'secondary.border'
                  }
                  onClick={() => {
                    !isVoted && onVote(optionsKeys[i])
                  }}
                >
                  {displayResults && (!preview || actionData?.closed) ? (
                    <>
                      <Box
                        position="absolute"
                        backgroundColor={
                          isSelectedOption ? 'flowpage.primaryColor' : 'secondary.border'
                        }
                        opacity=".5"
                        top="0"
                        bottom="0"
                        left="0"
                        right={`${100 - resultInPercent}%`}
                      />
                      <Box position="relative">
                        <Text>{option}</Text>
                      </Box>
                      <Box position="relative">
                        <Text mr="10px">{resultInPercent}%</Text>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box
                        position="absolute"
                        borderRadius="4px"
                        backgroundColor={
                          isSelectedOption ? 'flowpage.primaryColor' : 'primary.white'
                        }
                        opacity=".5"
                        top="0"
                        bottom="0"
                        left="0"
                        right="0"
                      />
                      <Box position="relative">
                        <Text>{option}</Text>
                      </Box>
                    </>
                  )}
                </Button>
              )
            } else {
              return null
            }
          })}
        </>
      </Box>
      {editLinkMode && (
        <EditLinkCardEndAdornment
          isLockedTemplateLink={isLockedTemplateLink}
          iconSrc={icons.arrowNextWhite}
          wrapperWidth="10%"
          iconWidth="18px"
          iconMargin={'0 0 0 auto'}
        />
      )}
    </Card>
  )
}
export const Manager: FC<ManagerProps> = ({ editWidget, link, handle }: ManagerProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const mobile = useIsMobile()
  const actionData = getValidatedActionDataOrThrow<'polling'>(link?.actionData, 'question')
  const results = Object.values(actionData?.results || {})
  const totalVotes = results.reduce((acc, val) => (val && acc !== undefined ? val + acc : acc), 0)

  const handleClose = (): void => setOpen(false)
  return (
    <>
      <DefaultManager
        handle={handle}
        editWidget={editWidget}
        iconUrl={'/static/icons/poll.svg'}
        iconProps={{ padding: '8px' }}
        link={link}
      >
        <>
          <Spacer mt="16px" />
          <Box
            width="100%"
            bg="#f3f5f6"
            p="0px 12px"
            height="50px"
            justifyContent="space-between"
            alignItems="center"
            backgroundColor="transparent"
          >
            <Text>{`${totalVotes} Response${totalVotes !== 1 ? 's' : ''}`}</Text>
            <Button
              colorVariant="primaryOnDark"
              height="30px"
              border="solid 1px #dadcdd"
              borderRadius="4px"
              onClick={e => {
                e.stopPropagation()
                setOpen(true)
              }}
              width="fit-content"
            >
              <Text variant="body/small" fontWeight="600">
                See Results
              </Text>
            </Button>
          </Box>
        </>
      </DefaultManager>

      <Modal
        open={open}
        onClose={handleClose}
        top={['150px', '150px', '0px']}
        variant={mobile ? ModalVariants.SHEET : ModalVariants.DESKTOP}
      >
        <Results link={link} handleClose={handleClose} />
      </Modal>
    </>
  )
}
