// Based on: https://stackoverflow.com/questions/16376161/javascript-set-filename-to-be-downloaded/16377813#16377813

const download = (content: string, fileName: string, mimeType: string): void => {
  const a = document.createElement('a')
  mimeType = mimeType || 'application/octet-stream'

  if (URL && 'download' in a) {
    //html5 A[download]

    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType
      })
    )
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(content) // only this mime type is supported
  }
}

export const downloadFromURL = async (url: string, fileName: string): Promise<Promise<void>> => {
  const a = document.createElement('a')

  if (URL && 'download' in a) {
    //html5 A[download]
    const image = await fetch(url)
    const imageBlob = await image.blob()

    const imageURL = URL.createObjectURL(imageBlob)
    a.setAttribute('href', imageURL)
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(url) // only this mime type is supported
  }
}

/**
 * Download a file from a URL.
 * Use this method when the source URL is not on the same domain as the current page and you need to use CORS.
 * Note that you cannot set the filename of the downloaded file when using this method.
 */
export const downloadFromURLWithCORS = async (url: string): Promise<Promise<void>> => {
  const a = document.createElement('a')

  if (URL && 'download' in a) {
    // html5 A[download]
    a.setAttribute('href', url)
    a.setAttribute('download', 'true')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href = 'data:application/octet-stream,' + encodeURIComponent(url) // only this mime type is supported
  }
}

export default download
